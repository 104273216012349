import styled from 'styled-components'

export const Wrapper = styled.div`
  ul {
  	display: grid;

	margin: 0;
	/* https://css-tricks.com/responsive-layouts-fewer-media-queries/ */

	--w: 280px;
	--n: 2;
	gap: 0;
	grid-template-columns: repeat(
	  auto-fit,
	  minmax(max(var(--w), 100%/ (var(--n) + 1) + 0.1%), 1fr)
	);
	grid-template-rows: auto;
  }
`
export const EmojiListContainer = styled.div`
	display: inline-block;
`

export const EmojiListItem = styled.li`
	margin: auto;

	text-align:left;

    font-size: var(--font-lg);
    padding-left: var(--space-2);
    margin-bottom: var(--space-2);

	list-style-type: none;
	::before {
		padding-right: var(--space-2);
		content: "${props => props.emoji || ""}";
		
	} 
    // Not supported yet in Safari
    // ::marker {
    // 	font-size: 2rem;
    // 	content: "${props => props.emoji || ""}";
    // };
  	
`