import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { Wrapper, EmojiListItem, EmojiListContainer } from './styles'
import { StaticImage } from "gatsby-plugin-image"
import Container from "components/common/Container";

export const Intro = () => {

	const {
		markdownRemark: {
			frontmatter: { name, descriptors }
		}
	} = useStaticQuery(graphql`
		query {
			markdownRemark {
				frontmatter {
					name
					title
					descriptors {
						descriptor
						emoji
					}
				}
			}
		}
	`)

	const descriptorItems = (descriptors) ? descriptors.map(it =>
		<EmojiListContainer key={it.descriptor} >
			<EmojiListItem emoji={it.emoji}>
				{it.descriptor}
			</EmojiListItem>
		</EmojiListContainer>
		) : null;

	return (
		<Wrapper as={Container} id='about'>
		      <StaticImage
		        src="../../../images/logo_512.png"
		        loading="eager"
		        placeholder="none"
		        width={64}
		        quality={100}
		        formats={["auto", "webp", "avif"]}
		        alt=""
		        style={{ marginBottom: `var(--space-3)` }}
		      />
			<h1>{name}</h1>
		    <ul>{descriptorItems}</ul>
		</Wrapper>
	)
}