import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "components/common/Container";
import {
  Wrapper,
  Description,
  ClickableWrapper,
  Content,
  StuffCard} from "./styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Stuffs = () => {
    const {
      allMarkdownRemark: {
        edges: {
          0: {
            node: {
              frontmatter: {
                stuffs
              }
            }
          }
        }
      }
    } = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {frontmatter: {stuffs: {elemMatch: {description: {ne: null}}}}}
          ) {
          edges {
            node {
              frontmatter {
                stuffs {
                  stuff
                  description
                  link
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        height: 52
                        placeholder:NONE
                        )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)

  const stuffItems = stuffs.map(it => {
    const img = getImage(it.image);

    return (
      <ClickableWrapper href={it.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={it.stuff}>
        <StuffCard>
            <Content>
              <GatsbyImage image={img} alt={it.stuff} />
              <Description>{it.description}</Description>
          </Content>
        </StuffCard>
      </ClickableWrapper>
      )
    });

  return (
    <Wrapper as={Container} id='stuff'>
        {stuffItems}
    </Wrapper>
  )
}
