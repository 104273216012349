import styled from "styled-components";

const Card = styled.div`
  padding: 1rem;
  background: #fff;
  width: 100%;
  overflow: hidden;

  margin: 2em 0 0 0;

  h4 {
    color: #212121;
  }
`;

export default Card;
