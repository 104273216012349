import styled from "styled-components";
import Card from "components/common/Card";
import colors from "tokens/colors";

export const ClickableWrapper = styled.a`
    text-decoration:none;
`

export const Wrapper = styled.div`
  padding: 0 0 4rem 0;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  margin: auto;

  @media (max-width: 1960px) {
    padding: 2rem 0 2rem;
  }
`

export const Content = styled.div`
  padding: 1rem 0;
`;

export const Description = styled.p`
  margin-top: 1em;
  margin-bottom: 0;
  color:${colors.primary};
`;

export const StuffCard = styled(Card)`
  text-align: left;

  box-shadow: 0 0.2em 0.8em 0 rgba(0, 0, 0, 0.45);

  &:hover {
      transform: scale(1.02);
  }

  a {
    font-size: 3em;
    color:${colors.primary};
  }
`;
